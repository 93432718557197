import React from 'react';
import { InputDialog } from 'wix-ui-tpa';

import { useTranslation } from '@wix/yoshi-flow-editor';
import { connect } from '../../../../common/components/runtime-context';
import { getRegionalSettings, getUpmModalSubscription, isUpmModalOpen } from '../../selectors';
import { RootState } from '../../state';
import { PaymentsWidget } from '@wix/cashier-payments-widget/lazy';
import { IActionCreators } from '../../../../types/internal-types';

type RuntimeProps = ReturnType<typeof mapRuntimeToProps>;

export const UpmModal = ({
  isOpen,
  subscription,
  actionCreators,
  language,
  IdentityParams,
  visitorId,
}: RuntimeProps) => {
  const { t } = useTranslation();
  const { msid, instance, siteOwnerId } = IdentityParams;

  const getNewPaymentMethodId = ()=> "y4bf118d-45da-40b4-a4c1-114d5c3baec5";


  return (
    <InputDialog
      title="My Dialog Title"
      subtitle="Subtitle"
      data-hook="upm-modal"
      isOpen={isOpen}
      onClose={() => actionCreators.closeUpmModal()}
      primaryButton={{
        text: 'Update',
        onClick: ()=>{
          actionCreators.submitUpm({subscriptionId:subscription.id!,paymentMethodId:getNewPaymentMethodId()})
        },
      }}
      secondaryButton={{
        text: 'Cancel', // TODO:add content
        onClick: () => actionCreators.closeUpmModal(),
      }}
    >
      {isOpen ? (
        <>
          <PaymentsWidget
            configuration={{
              locale: language,
              appId: IdentityParams.appDefinitionId,
              appInstanceId: IdentityParams.appInstanceId,
              appInstance: instance,
              amount: '0',
              currency: subscription.billingSettings!.currency!,
              msid: msid || '',
              siteOwnerId,
              visitorId,
            }}
            externalSubmitButton // ability to show own button
            onCrash={() => {
              console.log('crash');
            }} // Payments will show error message, Subscriptions disable button
            onError={() => {
              console.log('error');
            }}
            onApiInit={(api) => {
              console.log("mounted")
            }} // wait for an API
          />
        </>
      ) : null}
    </InputDialog>
  );
};

const mapRuntimeToProps = (state: RootState, _: {}, actionCreators: IActionCreators) => {
  return {
    isOpen: isUpmModalOpen(state),
    subscription: getUpmModalSubscription(state),
    actionCreators,
    language: getRegionalSettings(state),
    IdentityParams: state.IdentityParams,
    visitorId: state.user?.id,
  };
};

export default connect(mapRuntimeToProps)(UpmModal);
